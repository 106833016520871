exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bio-js": () => import("./../../../src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-download-pricelist-js": () => import("./../../../src/pages/download-pricelist.js" /* webpackChunkName: "component---src-pages-download-pricelist-js" */),
  "component---src-pages-free-mix-contest-index-js": () => import("./../../../src/pages/free-mix-contest/index.js" /* webpackChunkName: "component---src-pages-free-mix-contest-index-js" */),
  "component---src-pages-free-mix-contest-step-2-js": () => import("./../../../src/pages/free-mix-contest/step2.js" /* webpackChunkName: "component---src-pages-free-mix-contest-step-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

